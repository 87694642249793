@import './variables';

@font-face {
	font-family: 'Lato Regular';
	src: url('../../resources/fonts/Lato-Regular.ttf');
}

@font-face {
	font-family: 'Lato Bold';
	src: url('../../resources/fonts/Lato-Bold.ttf');
}

body {
	margin: 0;
	font-family: 'Lato Regular';
	font-size: 18px;
}

#root::after {
	content: '';
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: fixed;
	z-index: -1;
	background-repeat: no-repeat;
	background-position-y: 100%;
	background-position-x: center;
	background-size: cover;
	background-position: top;
	background: #1e1e1e !important;
}

.full {
	width: 100%;
}

.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.cursor {
	cursor: pointer;
}

.bold {
	font-weight: bold;
}

.padre {
	display: table;

	& .hijos {
		display: table-cell;
		vertical-align: middle;
	}
}

.blanco {
	color: #fff !important;
}

.rojo {
	color: $rojo;
}

.amarillo {
	color: $amarillo;
}

.mt30 {
	margin-top: 30px;
}

.mb30 {
	margin-bottom: 30px;
}

.uppercase {
	text-transform: uppercase;
}

.alignCenter {
	justify-content: center;
	align-items: center;
	display: flex;
}

.btn-rojo {
	border: 1px solid transparent !important;
	background-color: $rojo !important;
	color: #fff !important;
	padding: 5px !important;
	border-radius: 10px;
	transition: all 0.3s ease-out !important;
	text-transform: capitalize;

	&:hover {
		color: #fff !important;
		background-color: transparent !important;
		border: 1px solid #fff !important;
	}
}

.titulos {
	font-size: 32px;
	font-family: 'Lato Bold';
}

.likes-btn {
	margin: 5px;
	cursor: pointer;
}

.like-selected {
	color: $amarillo !important;
}

.contenedor-chat {
	width: 100%;
	height: 100%;
	& iframe {
		border: none;
	}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.contenedor-chat {
		height: 400px;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.contenedor-chat {
		height: 400px;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.contenedor-chat {
		height: 400px;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	.contenedor-chat {
		height: 100%;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
